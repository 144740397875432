import styled from "styled-components"

export const ResponsiveImage = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-color: #292929;
  border-style: solid;
  border-radius: 5px;
  object-fit: cover;
`
