import React from "react"
import GalleryTemplate from "../templates/galleryTemplate"
import { NavMenuProvider } from "../context/nav-menu"
import { Navigation } from "../components/shared/navigation"
import { MacBackGround } from "../components/shared/background"

export default () => (
  <NavMenuProvider>
    <MacBackGround>
      <Navigation />
      <GalleryTemplate />
    </MacBackGround>
  </NavMenuProvider>
)
