import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"

import Grid from "@material-ui/core/Grid"

import { Card } from "../components/shared/card"
import { ResponsiveImage } from "../components/shared/image"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
}))

const GalleryTemplate = ({ data }) => {
  const {
    allMarkdownRemark: { nodes },
  } = data // data.allMarkdownRemark.nodes holds our menu items

  const classes = useStyles()

  const images = nodes.reduce((acc, cur, idx) => {
    return [
      ...acc,
      {
        img: cur.frontmatter.image,
        title: cur.frontmatter.title,
        cols: Math.floor(Math.random() * 2) + 1,
      },
    ]
  }, [])

  return (
    <div className={classes.root}>
      {images.length && (
        <GridList cellHeight={175} className={classes.gridList} cols={4}>
          {images.map(image => (
            <GridListTile key={image.img} cols={image.cols || 1}>
              <img src={image.img} alt={image.title} />
            </GridListTile>
          ))}
        </GridList>
      )}
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query GalleryTemplate {
        allMarkdownRemark(
          filter: { frontmatter: { path: { regex: "/gallery/i" } } }
        ) {
          nodes {
            frontmatter {
              image
              title
              description
            }
            id
          }
        }
      }
    `}
    render={data => <GalleryTemplate data={data} />}
  />
)
